<template>
  <vx-card title="Glosario de Términos">
    <template slot="actions">
      <div>
        <vs-button
          v-if="$can('create', 'terminology')"
          size="small" color="success" type="filled" @click="createTerminology()"
        >
          Nuevo
        </vs-button>
      </div>
    </template>

    <vs-table max-items="10" pagination search stripe :data="terminologies" :hoverFlat="true">
      <template slot="thead">
        <vs-th sort-key="name">Nombre</vs-th>
        <vs-th sort-key="description">Descripción</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="record" :key="index" v-for="(record, index) in data">
          <vs-td>
            <p class="font-medium">{{ record.name }}</p>
          </vs-td>
          <vs-td>
            <p class="font-medium" v-html="record.description.html"/>
          </vs-td>
          <vs-td>
            <div class="flex">
              <vx-tooltip text="Editar">
                <vs-button
                  :disabled="!($can('retrieve', 'terminology') || $can('update', 'terminology'))"
                  radius size="large" color="success" type="flat" icon-pack="feather" icon="icon-edit"
                  @click="retrieveEntry(record)"
                />
              </vx-tooltip>
              <vx-tooltip text="Publicar">
                <vs-button
                  radius size="large" :color="record.in_production ? 'dark' : 'success'" type="flat" icon-pack="feather"
                  icon="icon-send"
                  :disabled="record.in_production"
                  @click="confirmCommit(record)"
                />
              </vx-tooltip>
              <vx-tooltip text="Eliminar">
                <vs-button
                  :disabled="!$can('delete', 'terminology')"
                  radius size="large" color="warning" type="flat" icon-pack="feather" icon="icon-trash-2"
                  @click="confirmDelete(record)"
                />
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <vs-popup :title="popupTitle" :active.sync="popupActive" button-close-hidden>
      <template>
        <div class="vx-row">
          <div class="vx-col w-full">
            <label for="name" class="w-full select-large">Nombre <i>(requerido)</i></label>
            <vs-input id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full"/>
            <br/>
          </div>

          <div class="vx-col w-full">
            <label for="description" class="w-full select-large">Descripción <i>(requerido)</i></label>
            <editor id="description" :content.sync="entryModel.description.html" :resourceType="resource"/>
            <br/>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex flex-wrap justify-between">
              <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-button size="small" color="danger" type="border" class="mr-3 mb-2" @click="popupDismiss">
                  Cancelar
                </vs-button>
                <vs-button
                  size="small" class="mb-2" @click="createOrUpdateEntry(entryModel)"
                  :disabled="!validateForm"
                >
                  Guardar
                </vs-button>
              </vs-row>
            </div>
          </div>
        </div>
      </template>
    </vs-popup>
  </vx-card>
</template>

<script>
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification.js";
  import Editor from "./../../components/Editor.vue";
  import vSelect from "vue-select";
  import * as notificationMixins from "../../utils/notificationMixins";

  let notifier = new Notifier();
  const ENTRIES_CLIENT = new EntriesClient("terminologies");

  export default {
    data() {
      return {
        resource: "terminologies",
        edit: false,
        popupActive: false,
        popupTitle: "",
        terminologies: [],
        entryModel: {
          name: "",
          description: {
            html: "",
          },
        },
      };
    },

    mounted() {
      this.getEntries();
    },

    computed: {
      validateForm() {
        return !this.errors.any()
          && this.entryModel.name !== ''
          && this.entryModel.description.html !== ""
          && this.entryModel.description.html !== null;
      }
    },

    methods: {
      async getEntries() {
        this.$vs.loading();
        await ENTRIES_CLIENT.all()
          .then(response => {
            this.$vs.loading.close();
            this.terminologies = response.data;
          })
          .catch(error => {
            this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry(data) {
        let body = [];

        if (data !== null && data !== undefined && data !== "") {
          this.$vs.loading();
          this.$vs.container = "";

          if (this.edit) {
            body = JSON.stringify({
              id: data.id,
              name: data.name,
              description: data.description,
            });

            await ENTRIES_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: body})
              .then(response => {
                notifier.notification("updated");
                this.popupDismiss();
              })
              .catch(error => {
                this.errors = error.response.data.errors;
                notifier.alertMessage("error");
              });

          } else {
            body = JSON.stringify({name: data.name, description: data.description});

            await ENTRIES_CLIENT.create({data: body})
              .then(response => {
                notifier.notification("created");
                this.popupDismiss();
              })
              .catch(error => {
                this.$vs.loading.close();
                notifier.alertMessage("error");
                this.errors = error.response.data.errors;
              });
          }
        }
      },

      async deleteTerminologies(terminologyId) {
        if (terminologyId !== undefined && terminologyId !== null && terminologyId !== "") {
          this.$vs.loading();
          await ENTRIES_CLIENT.delete({pathParameters: {entryId: terminologyId}})
            .then(response => {
              notifier.notification("deleted");
            })
            .catch(error => {
              notifier.alertMessage("error");
            });
          this.popupDismiss();
        }
      },

      confirmDelete(data) {
        notifier.deleteMessage().then(result => {
          if (result.value) {
            this.deleteTerminologies(data.id);
          } else {
            this.getEntries();
          }
        });
      },

      confirmCommit(entry) {
        notificationMixins.CommitEntryNotification.fire()
          .then(result => {
            if (result.value) {
              if (entry.id !== null && entry.id !== undefined && entry.id !== "") {
                this.$vs.loading();
                ENTRIES_CLIENT.commit({pathParameters: {entryId: entry.id}})
                  .then(response => {
                    notifier.notification("committed");
                    this.getEntries();
                  })
                  .catch(error => {
                    notifier.alertMessage("error");
                  });
                this.$vs.loading.close();
              }
            }
          });
      },

      createTerminology() {
        this.edit = false;
        this.popupTitle = "Nuevo";
        this.popupActive = true;
      },

      retrieveEntry(terminology) {
        this.cleanModel();
        this.entryModel = terminology;
        this.popupTitle = "Editar";
        this.edit = true;
        this.popupActive = true;
      },

      cleanModel() {
        this.entryModel = {
          name: null,
          description: {
            html: null,
          },
        };
      },

      popupDismiss() {
        this.$vs.loading.close();
        this.popupActive = false;
        this.getEntries();
      }
    },

    components: {
      Editor,
      "v-select": vSelect
    },
  };
</script>

<style scoped>
  .style-chosser .vs__dropdown-menu {
    max-height: 120px;
  }

  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }
</style>
